// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-add-js": () => import("./../../../src/pages/add.js" /* webpackChunkName: "component---src-pages-add-js" */),
  "component---src-pages-deleted-js": () => import("./../../../src/pages/deleted.js" /* webpackChunkName: "component---src-pages-deleted-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-requests-js": () => import("./../../../src/pages/requests.js" /* webpackChunkName: "component---src-pages-requests-js" */)
}

