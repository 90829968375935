export const shibbolethConfig = {
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_ntVMO3Ed8',
    userPoolWebClientId: '3esl2ao463g6plfuor2oggpr9',
    oauth: {
      domain: "auth-mfa.aws.york.ac.uk",
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.GATSBY_REDIRECT_URL}`,
      responseType: 'code'
    }
  }
};
