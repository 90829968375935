import { Authenticator } from "aws-amplify-react"
import React from "react"
import Amplify from "aws-amplify"
import "./src/styles/styles.css"
import { shibbolethConfig } from "./src/components/shibbolethConfig"
import { Auth } from "aws-amplify"
import { authenticatedLoginContext } from './src/components/authenticatedLoginContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

Amplify.configure(shibbolethConfig);

export function wrapRootElement({ element }) {
  return(
    <Authenticator hideDefault={true}>
      <Handler>
        {element}
      </Handler>
    </Authenticator>
  );
}

class Handler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: 'unidentified human',
      admin: null,
      offsite: null
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      var admin;

      // Once the user has signed in, get their username and add to state.
      if (this.props.authState === 'signedIn') {
        Auth.currentSession()
          .then(async data => {

            // First, get the authenticated user's username.
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            const { username } = authenticatedUser;
            const parts = username.split('_');
 
            // Next find out if they are an admin.
            await fetch(`${process.env.GATSBY_API_URL}/permissions`,
            {
              method: 'GET',
              mode: 'cors',
              headers: new Headers({
                'Authorization': 'Bearer ' + data.idToken.jwtToken
              })
            }
            )
            .then(async res => {
              const result = await res.json();

              if (result.message === 'admin') {
                admin = true;
              } else if (result.message === 'standard') {
                admin = false;
              } else {
                admin = null;
              }

              this.setState({
                username: parts[1],
                admin: admin,
                offsite: false
              });
            })

            // If API calls fails, assume it is because the user is off campus.
            .catch(res => {
              this.setState({
                offsite: true
              });
            });
          })
          .catch(err => console.log(err));
      }
    }
  }

  render() {
    const { username, offsite } = this.state;

    if (offsite === true) {
      return(
        <React.Fragment>
          <p>Vault can only be accessed from the campus network e.g. via the VPN.</p>
        </React.Fragment>
      );
    }

    if (username === 'unidentified human') {
      return(
        <React.Fragment>
          <br />
          <div class="o-gride__row">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button class="c-btn c-btn--success c-btn--medium" type="button"

              onClick={() => Auth.federatedSignIn({ provider: "Shibboleth" })}
            >
            Login&nbsp;<FontAwesomeIcon icon={faUser} />
            </button>
          </div>
        </React.Fragment>
      );
    }

    if (this.state.admin !== null) {
      return(
        <React.Fragment>
          <authenticatedLoginContext.Provider value={this.state}>
            {this.props.children}
          </authenticatedLoginContext.Provider>
        </React.Fragment>
      );
    } else {
      return(
        <React.Fragment>
          <p>Unauthorized.</p>
        </React.Fragment>
      );
    }
  }
}
